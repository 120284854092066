import { QueryClientProvider, QueryClient } from "react-query";

import Routes from "./routes";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes />
    </QueryClientProvider>
  );
}

const queryClient = new QueryClient();

export default App;
