import axios from "axios";

/**
 * 전화번호를 가지고 인증해 url을 받는다.
 * @param {numberic} phone 전화번호
 * @returns
 */
export const auth = async (phone) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_HOST}/nomadcoder/api/key`,
    {
      phone,
    }
  );
  return data;
};
