import React from "react";
import { Switch, Route } from "react-router-dom";

import Key from "../../components/pages/nomadcoder/Key";

function NomadcoderRoutes() {
  return (
    <Switch>
      {/* <Route exact path="/nomadcoder/key" component={() => <Key />} /> */}
      <Route exact path="/" component={() => <Key />} />
    </Switch>
  );
}

export default NomadcoderRoutes;
