import React from "react";
import { useMutation } from "react-query";
import { makeStyles } from "@material-ui/styles";

import { auth } from "../../../../apis/nomadcoder/keyApis";

function Key() {
  const classes = useStyles();
  const { mutate, isLoading, isError } = useMutation(auth, {
    onSuccess: ({ order, data }) => {
      if (order === "redirect") {
        window.location.href = data.redirectUri;
      } else if (order === "alert") {
        alert(data.alert);
      }
    },
    onError: (e) => {
      if (e?.response?.data?.errorMessage) {
        alert(e.response.data.errorMessage);
      }
    },
  });

  /** submit */
  const handleSubmit = (e) => {
    e.preventDefault();
    const { phone } = Array.from(new FormData(e.target).entries()).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );

    if (!phone) {
      return alert("전화번호를 입력해주세요");
    }

    console.log("> ", phone, process.env.REACT_APP_API_HOST);
    mutate(phone);
  };

  /** render */
  return (
    <div className={classes.root}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <input
            name="phone"
            type="number"
            placeholder="전화번호를 '-'빼고 입력"
          />
          <div style={{ marginTop: 4, fontSize: "0.8rem" }}>
            {isError && <span>리퀘스트에 실패 하였습니다.</span>}
          </div>
          <button type="submit">전송</button>
        </form>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& > *": {
      display: "block",
    },
    "& > * + *": {
      marginTop: 20,
    },
    "& input, button": {
      height: "30px",
      padding: "0 8px",
    },
  },
});

export default Key;
