import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import NomadcoderRoutes from "./NomadcoderRoutes";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/nomadcoder" component={() => <NomadcoderRoutes />} /> */}
        <Route path="/" component={() => <NomadcoderRoutes />} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
